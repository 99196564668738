class DataFilter {
  private container: HTMLElement;
  private headerElement: HTMLElement | null;
  private contentElement: HTMLElement | null;
  private filterTriggers!: NodeListOf<HTMLElement>;
  private filterTargets!: NodeListOf<HTMLElement>;
  private defaultMessageElement: HTMLElement | null = null;

  constructor(container: HTMLElement) {
    this.container = container;
    this.headerElement = container.querySelector('[data-filter-header]');
    this.contentElement = container.querySelector('[data-filter-content]');

    if (!this.headerElement || !this.contentElement) {
      console.error('Required filter elements not found');
      return;
    }

    // Create default message element if it doesn't exist
    this.defaultMessageElement = this.contentElement.querySelector('[data-filter-default]');
    if (!this.defaultMessageElement) {
      this.defaultMessageElement = document.createElement('div');
      this.defaultMessageElement.setAttribute('data-filter-default', '');
      this.defaultMessageElement.className = 'filters__default-message';
      this.defaultMessageElement.style.display = 'none';
      this.contentElement.appendChild(this.defaultMessageElement);
    }

    this.filterTriggers = this.headerElement.querySelectorAll('[data-filter-trigger]');
    this.filterTargets = this.contentElement.querySelectorAll('[data-filter-target]');

    // Set reset filter button as active by default
    const resetTrigger = Array.from(this.filterTriggers).find(
      (trigger) => trigger.getAttribute('data-filter-trigger') === ''
    );
    if (resetTrigger) {
      resetTrigger.setAttribute('data-is-active', 'true');
    }

    // Initialize default state - show default message if no filter targets
    if (this.filterTargets.length === 0) {
      if (this.defaultMessageElement) {
        this.defaultMessageElement.textContent = 'Zatím žádné komentáře.';
        this.defaultMessageElement.style.display = 'block';
      }
    }

    this.initializeEventListeners();
  }

  private initializeEventListeners(): void {
    this.filterTriggers.forEach((trigger) => {
      trigger.addEventListener('click', (event) => {
        event.preventDefault();
        this.handleFilterClick(trigger);
      });
    });
  }

  private handleFilterClick(clickedTrigger: HTMLElement): void {
    // Reset all trigger active states
    this.filterTriggers.forEach((trigger) => {
      trigger.removeAttribute('data-is-active');
    });

    // Mark clicked trigger as active
    clickedTrigger.setAttribute('data-is-active', 'true');

    // Get filter value
    const filterValue = clickedTrigger.getAttribute('data-filter-trigger') || '';
    const filterLabel = filterValue !== 'reset' ? clickedTrigger.textContent : '';

    // Track visible items
    let visibleItemCount = 0;

    // Filter content
    this.filterTargets.forEach((target) => {
      const targetValue = target.getAttribute('data-filter-target') || '';

      // If no filter value (reset) or matching filter value, show the element
      if (filterValue === 'reset' || targetValue === filterValue) {
        target.style.display = '';
        visibleItemCount++;
      } else {
        target.style.display = 'none';
      }
    });

    // Handle default message visibility and content
    if (this.defaultMessageElement) {
      if (filterLabel)
        this.defaultMessageElement.textContent = `Zatím žádné ${filterLabel.toLowerCase()}.`;
      else this.defaultMessageElement.textContent = 'Zatím žádné komentáře.';
      if (visibleItemCount === 0) this.defaultMessageElement.style.display = 'block';
      else this.defaultMessageElement.style.display = 'none';
    }
  }

  public applyFilter(filterValue: string): void {
    const triggerToActivate = Array.from(this.filterTriggers).find(
      (trigger) => trigger.getAttribute('data-filter-trigger') === filterValue
    );

    if (triggerToActivate) {
      this.handleFilterClick(triggerToActivate);
    }
  }

  public resetFilter(): void {
    const resetTrigger = Array.from(this.filterTriggers).find(
      (trigger) => trigger.getAttribute('data-filter-trigger') === 'reset'
    );

    if (resetTrigger) {
      this.handleFilterClick(resetTrigger);
    }
  }
}

document.addEventListener('DOMContentLoaded', () => {
  const filterContainers = document.querySelectorAll('[data-filter]');
  filterContainers.forEach((container) => {
    try {
      const filter = new DataFilter(container as HTMLElement);
    } catch (error) {
      console.error('Filter initialization error:', error);
    }
  });
});

export default DataFilter;
