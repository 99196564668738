/*

Flash messages https://cncenter.atlassian.net/browse/FEG-556

Slouží pro informování uživatele o nějakém stavu (výsledek odeslání formuláře apod.)

Příklad volání:

  await FlashMessage({
    msg: 'Lorem ipsum <a href="http://blesk.cz">GO TO</a>.',
    type: 'info',
    autoHide: false,
  });

  */

import SVGImage from '../images/SVGImage';

const CLASS_VISIBLE = 'flash-message--visible';

export interface FlashMessageProps {
  msg: string;
  type?: 'success' | 'error' | 'warning' | 'info';
  seconds?: number;
  autoHide?: boolean;
}

export const flashMessage = async ({
  msg,
  type = 'info',
  seconds = 4,
  autoHide = true,
}: FlashMessageProps) => {
  const messagesContainer = document.querySelector('[data-flash-messages]') as HTMLDivElement;
  if (!messagesContainer) throw new Error('Flash messages container not found');

  const hideAfterSeconds = seconds;

  // Šablona flash message
  const template = document.querySelector('[data-flash-message-template]') as HTMLTemplateElement;

  // Vytvořit flash message
  const message = template.content.firstElementChild?.cloneNode(true) as HTMLDivElement;

  // Získat textový obsah
  const content = message.querySelector('.flash-message__text') as HTMLDivElement;

  // Nastavit typ zprávy
  message.classList.add(`flash-message--${type}`);

  // Nastavit ikony pro jednotlivé typy
  const iconCodes = new Map<string, string>();
  iconCodes.set('info', 'icon-info');
  iconCodes.set('error', 'icon-danger');
  iconCodes.set('warning', 'icon-warning');
  iconCodes.set('success', 'icon-check-circle-outline-green');

  // Přidat ikonu
  await SVGImage({
    code: iconCodes.get(type) as string,
    className: 'flash-message__icon',
    targetElement: message,
    prepend: true,
    title: `Ikona ${type}`,
  });

  // Nastavit text zprávy
  content.innerHTML = msg;

  // Skrýt zprávu
  const hideMessage = () => {
    message.classList.toggle(CLASS_VISIBLE);
    // Zde musí být prodleva, jinak by nebyl plynulý přechod
    setTimeout(() => {
      message.remove();
    }, 1000 * 1);
  };

  // Zavřít flash message po kliknutí na křížek
  const btnClose = message.querySelector('[data-flash-message-close]') as HTMLButtonElement;
  btnClose.addEventListener('click', () => hideMessage());

  // Automaticky odstranit zprávu po určitém čase
  if (autoHide)
    setTimeout(() => {
      hideMessage();
    }, 1000 * hideAfterSeconds);

  // Přidat do zpráv
  if (!messagesContainer.contains(message)) {
    messagesContainer.append(message);
  }

  // Zobrazit zprávu
  message.classList.add(CLASS_VISIBLE);
};

export default flashMessage;
