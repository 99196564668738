import { TrackerRing } from '@components';

import * as featuresArtObj from './featureCollections/featuresArtObj';
import * as featuresSystem from './featureCollections/featuresSystem';
import * as featuresBaseGUI from './featureCollections/featuresBaseGUI';
import * as features from './featureCollections/features';
import { isIOS } from '@utils/IOS';

import { SentryInit } from '@features/sentry';

SentryInit();

TrackerRing();

if (isIOS()) {
  document.body.classList.add('ios');
}

const importedModules = {
  ...featuresArtObj,
  ...featuresSystem,
  ...featuresBaseGUI,
  ...features,
};
const results = Object.values(importedModules).map((func) => func());

// /* anketa v clanku */
// const enquiry = async () => {
//   const enquiryElements = document.querySelectorAll(
//     '.enquiry-bars'
//   ) as NodeListOf<HTMLElement> | null;
//   if (enquiryElements && enquiryElements.length) {
//     const { enquiryBars } = await import(
//       /* webpackChunkName: 'chunk-enquiry' */
//       '../box/article/EnquiryBars/enquiryBars'
//     );
//     enquiryBars(enquiryElements);
//   }
// };
// const todayResultSliderHP = async () => {
//   const sliderElement = document.querySelector('.today-results');
//
//   if (sliderElement) {
//     const { todayResultsSlider } = await import(
//       /* webpackChunkName: 'thoughtsArticleSwiper' */
//       '../components/todayResults/slider'
//     );
//
//     todayResultsSlider(sliderElement);
//     window.addEventListener('resize', () => todayResultsSlider(sliderElement));
//   }
// };
//
// const previewMatchesListVideoSliderInit = async () => {
//   const sliderElements = document.querySelectorAll('.match-oveview-video-list');
//
//   if (sliderElements && sliderElements.length) {
//     const { previewMatchesListVideoSlider } = await import(
//       /* webpackChunkName: 'thoughtsArticleSwiper' */
//       '../components/sportmod/preview-matches-list-video/slider'
//     );
//
//     previewMatchesListVideoSlider(sliderElements);
//     window.addEventListener('resize', () => previewMatchesListVideoSlider(sliderElements));
//   }
// };
//
// const headerMenuFlat = async () => {
//   const header = document.querySelector('header') as HTMLDivElement | null;
//   if (header) {
//     const { Header } = await import(
//       /* webpackChunkName: 'chunk-headermenuflat' */
//       '../box/header/HeaderMenuFlat'
//     );
//     Header(header);
//   }
// };
//
// /** Spusteni dynamickych importu */
// const loadDynamicImports = () => {
//   enquiry();
//   todayResultSliderHP();
//   previewMatchesListVideoSliderInit();
//   headerMenuFlat();
// };
//
// loadDynamicImports();

Promise.all(results).then(
  () => {
    // if (process.env.NODE_ENV === 'development') console.log('Všechny moduly načteny.');
  },
  () => {
    // if (process.env.NODE_ENV === 'development') console.log('Some modules not loaded');
  }
);
