// Animovaný loader pro případ, kdy se něco děje na pozadí

// TODO: vytvořit animovaný loader https://cncenter.atlassian.net/browse/GRF-1426

interface Props {
  addTo?: HTMLElement;
  image?: string;
  size?: number;
}

const SVGLoader = {
  create: ({ addTo, image = 'loader', size }: Props = {}): HTMLDivElement | HTMLObjectElement => {
    // TODO: přes HTML/CSS

    // Připravit obrázek
    const objectElement = document.createElement('object');
    objectElement.type = 'image/svg+xml';
    objectElement.data = `/dist-isport/assets/default/${image}.svg`;
    objectElement.classList.add('svg-loader');
    if (size) {
      objectElement.style.width = `${size.toString()}px`;
      objectElement.style.height = `${size.toString()}px`;
    }

    // Přidat wrapper
    const divElement = document.createElement('div');
    divElement.append(objectElement);

    // Vložit do konkrétního elementu
    // Pokud již svgLoader v elementu je, vrať tento a neduplikuj
    if (addTo) {
      let svgLoader = addTo.querySelector('.svg-loader');
      if (!svgLoader) {
        addTo.append(divElement);
        svgLoader = addTo.querySelector('.svg-loader');
      }

      return svgLoader as HTMLObjectElement;
    }

    return divElement;
  },
};

export default SVGLoader;
