/*
  FEATURE MODULES | samostatné funkční celky
*/

// GALERIE | Obrázky, videa, ...
export const gallery = async () => {
  const galleryElement: HTMLDivElement | null = document.querySelector('[data-gallery-init]');

  if (galleryElement) {
    const Gallery = await import(
      /* webpackChunkName: 'default/chunk-gallery' */
      '@features/gallery/Gallery'
    );

    Gallery.default();
  }
};

// ENQUIRY | Anketa výsledky po přihlášení
export const enquiry = async () => {
  const enquiryElements: NodeListOf<HTMLButtonElement> = document.querySelectorAll('.enquiry-bars');

  if (enquiryElements && enquiryElements.length) {
    const { enquiryBars } = await import(
      /* webpackChunkName: 'default/chunk-enquiry' */
      '@box/article/EnquiryBars/enquiryBars'
    );

    enquiryBars(enquiryElements);
  }
};

// SURVEY | Anketa
export const survey = async () => {
  const elements: NodeListOf<HTMLButtonElement> = document.querySelectorAll('.survey');

  if (elements && elements.length) {
    const { survey } = await import(
      /* webpackChunkName: 'default/chunk-survey' */
      '../../box/article/Survey/survey'
    );

    survey(elements);
  }
};

// FÓRUM | Kompletní funkcionalita diskuzí
export const forum = async () => {
  const forumDiv: HTMLDivElement | null = document.querySelector('[data-forum]');

  if (forumDiv) {
    const Forum = await import(
      /* webpackChunkName: 'default/chunk-forum' */
      '@box/forum/Forum'
    );

    Forum.default();
  }
};

// RECOMBEE | Načítání + překreslování článků
export const recombeeArticles = async () => {
  const recombeeWrappers: NodeListOf<HTMLDivElement> = document.querySelectorAll('[data-recombee]');

  if (recombeeWrappers && recombeeWrappers.length) {
    const { Recombee } = await import(
      /* webpackChunkName: 'default/chunk-recombee' */
      '@boxIsport/listArticle/Recombee/Recombee'
    );

    Recombee(recombeeWrappers);
  }
};

// LIST-ARTICLE | Asynchronní donačítání článků
export const loadMore = async () => {
  const loadMoreElements: NodeListOf<HTMLLinkElement> =
    document.querySelectorAll('[data-load-more]');

  if (loadMoreElements && loadMoreElements.length) {
    const { LoadMore } = await import(
      /* webpackChunkName: 'default/chunk-load-more' */
      '@boxIsport/listArticle/LoadMore/LoadMore'
    );

    LoadMore(loadMoreElements);
  }
};

// LIST-ARTICLE | Odkryvání přednačtených doporučených článků
export const uncoverBehavArticles = async () => {
  const uncoverBehavArticlesBtn = document.querySelector<HTMLLinkElement>('.uncover-btn');

  if (uncoverBehavArticlesBtn) {
    const { UncoverBehavArticles } = await import(
      /* webpackChunkName: 'default/chunk-uncover-behav-articles' */
      '@box/listArticle/UncoverBehavArticles'
    );

    UncoverBehavArticles();
  }
};

// REKLAMA | HALFPAGE REPEATER MANAGER
export const halfPageManager = async () => {
  const topAside: HTMLElement | null = document.querySelector(
    '[data-first-content] > aside:not(.no-repeaters)'
  );
  const bottomAside: HTMLElement | null = document.querySelector(
    '.main--second-content > aside:not(.no-repeaters)'
  );
  const footer: HTMLDivElement | null = document.querySelector('[data-footer-trigger-aside]');
  if ((topAside || bottomAside) && footer) {
    const HalfPageManager = await import(
      /* webpackChunkName: 'default/chunk-ads-repeater' */
      '@box/ads/HalfPageManager/HalfPageManager'
    );

    HalfPageManager.default();
  }
};

// NEWSLETTER
export const newsletter = async () => {
  const newsletters = document.querySelector<HTMLButtonElement>('.newsletters');

  if (newsletters) {
    const { Newsletter } = await import(
      /* webpackChunkName: 'default/newsletter' */
      '@boxIsport/newsletter'
    );

    Newsletter();
  }
};

// COMPANY SUBSCRIPTION
export const companySubscription = async () => {
  const CompanySubscriptionSelector = document.querySelector<HTMLElement>('.company-subscription');

  if (CompanySubscriptionSelector) {
    const { CompanySubscription } = await import(
      /* webpackChunkName: 'default/companySubscription' */
      '@box/companySubscription/companySubscription'
    );

    CompanySubscription();
  }
};

// SWIPER
export const swipers = async () => {
  const swiperSelectors = [...document.querySelectorAll('[data-swiper]')];

  if (swiperSelectors.length > 0) {
    const { Swiper } = await import(
      /* webpackChunkName: 'default/swiper' */
      '@featuresIsport/swiper'
    );

    for (const swiperSelector of swiperSelectors) {
      if (swiperSelector instanceof HTMLDivElement) {
        Swiper(swiperSelector);
      }
    }
  }
};

// things article swiper
export const thingsArticleSwiper = async () => {
  const swiperThoughts: HTMLTemplateElement | null = document.querySelector('[data-thoughts]');

  if (swiperThoughts) {
    const { ThoughtsScroll } = await import(
      /* webpackChunkName: 'default/thingsArticleSwiper' */
      '@features/thingsArticleSwiper'
    );

    ThoughtsScroll(swiperThoughts);
  }
};

export const editorialPromo = async () => {
  const editorialPromoMagazines = ['reflex', 'e15', 'zeny'];
  const domain = window.location.hostname;

  if (editorialPromoMagazines.some((magazine) => domain.includes(magazine))) {
    const { EditorialPromo } = await import(
      /* webpackChunkName: 'default/chunk-editorial-promo' */
      '@features/editorialPromo/EditorialPromo'
    );

    EditorialPromo();
  }
};

export const topicBanner = async () => {
  const topicBannerElement: HTMLTemplateElement | null =
    document.querySelector('[data-topic-banner]');

  if (topicBannerElement) {
    const { TopicBanner } = await import(
      /* webpackChunkName: 'default/topic-banner' */
      '@features/topicBanner'
    );
    TopicBanner(topicBannerElement);
  }
};

// INSTAGRAM
export const instagramBox = async () => {
  const instagramBoxSelectors = [...document.querySelectorAll('[data-ig-box]')];

  if (instagramBoxSelectors.length > 0) {
    const { InstagramBox } = await import(
      /* webpackChunkName: 'default/instagram' */
      '@features/instagram/instagramBox'
    );

    for (const instagramBoxSelector of instagramBoxSelectors) {
      if (instagramBoxSelector instanceof HTMLElement) {
        await InstagramBox(instagramBoxSelector);
      }
    }
  }
};

// piano predplatne links
export const successReturnUrlAttacher = async () => {
  const searchUrlParams = new URLSearchParams(window.location.search);
  const successReturnUrl = searchUrlParams.get('success_return_url');
  if (!successReturnUrl) return;

  let linkTargets = [
    ...document.querySelectorAll('a.piano-landingpage__offer__button'),
  ] as Array<HTMLAnchorElement>;
  if (!linkTargets.length) return;

  linkTargets = linkTargets.splice(0, 2);

  const { SuccessReturnUrlAttacher } = await import(
    /* webpackChunkName: 'default/piano' */
    '@features/piano/index'
  );

  SuccessReturnUrlAttacher(successReturnUrl, linkTargets);
};

// Google One click Login Porta
export const portaInit = async () => {
  const userLogging: HTMLDivElement | null = document.querySelector('[data-user-not-logged]'); //Kontrolujeme, jestli uzivatel uz neni prihlaseny.

  if (userLogging) {
    const { PortaInit } = await import(
      /* webpackChunkName: 'default/portaInit' */
      '@features/porta/PortaInit'
    );

    await PortaInit();
  }
};

// JAKEBOX
export const jakeBox = async () => {
  const jakeBoxSelector = document.querySelector('[data-jakebox]');

  if (jakeBoxSelector) {
    const { JakeBox } = await import(
      /* webpackChunkName: 'default/jakebox' */
      '@features/jake/jakeBox'
    );

    if (jakeBoxSelector instanceof HTMLElement) {
      JakeBox(jakeBoxSelector);
    }
  }
};

// COLLAPSER
export const collapsers = async () => {
  const collapserSelectors: HTMLDivElement[] | [] = Array.from(
    document.querySelectorAll('[data-collapser]')
  );

  if (collapserSelectors.length > 0) {
    const { Collapser } = await import(
      /* webpackChunkName: 'default/collapser' */
      '@features/collapser'
    );

    for (const collapserSelector of collapserSelectors) {
      Collapser(collapserSelector);
    }
  }
};

// AUTHOR CARD LIST
export const authorCardList = async () => {
  const authorCardListSelector: HTMLDivElement | null =
    document.querySelector('[data-author-card-list]');

  if (authorCardListSelector) {
    const { AuthorCardList } = await import(
      /* webpackChunkName: 'default/author-card-list' */
      '@features/authorCardList'
    );

    AuthorCardList(authorCardListSelector);
  }
};

export const todayResultSliderHP = async () => {
  const sliderElement: HTMLDivElement | null = document.querySelector('.today-results');

  if (sliderElement) {
    const { todayResultsSlider } = await import(
      /* webpackChunkName: 'chunk-todayResultSliderHP' */
      '../../components/todayResults/slider'
    );

    todayResultsSlider(sliderElement);
    window.addEventListener('resize', () => todayResultsSlider(sliderElement));
  }
};

export const previewMatchesListVideoSliderInit = async () => {
  const sliderElements: NodeListOf<HTMLDivElement> = document.querySelectorAll(
    '.match-oveview-video-list'
  );

  if (sliderElements && sliderElements.length) {
    const { previewMatchesListVideoSlider } = await import(
      /* webpackChunkName: 'chunk-previewMatchesListVideoSlider' */
      '../../components/sportmod/preview-matches-list-video/slider'
    );

    previewMatchesListVideoSlider(sliderElements);
    window.addEventListener('resize', () => previewMatchesListVideoSlider(sliderElements));
  }
};

export const headerMenuFlat = async () => {
  const header = document.querySelector('header') as HTMLDivElement | null;
  if (header) {
    const { Header } = await import(
      /* webpackChunkName: 'chunk-headermenuflat' */
      '../../box/header/HeaderMenuFlat'
    );
    Header(header);
  }
};

// Zobrazení a skrytí photo credit popisku
export const infoToggle = async (): Promise<void> => {
  const triggerElements = Array.from(
    document.querySelectorAll<HTMLElement>('[data-info-toggle-trigger]')
  );

  if (triggerElements.length > 0) {
    const { default: InfoToggle } = await import(
      /* webpackChunkName: 'default/info-toggle' */
      '@featuresIsport/infoToggle'
    );

    triggerElements.forEach(InfoToggle);
  }
};

// TABS
export const tabs = async () => {
  const tabsSelectors = Array.from(document.querySelectorAll('[data-tabs]')) as HTMLDivElement[];

  if (tabsSelectors.length > 0) {
    const { Tabs } = await import(
      /* webpackChunkName: 'default/tabs' */
      '@featuresIsport/tabs'
    );

    for (const tabsSelector of tabsSelectors) {
      if (tabsSelector instanceof HTMLDivElement) {
        Tabs(tabsSelector);
      }
    }
  }
};

export const dayResultDatepicker = async () => {
  const datepickerCalendarDropdown: HTMLDivElement | null = document.querySelector(
    '[data-sportmod-day-result-datepicker]'
  );

  if (datepickerCalendarDropdown) {
    const { sportmodDayResultDatepicker } = await import(
      /* webpackChunkName: 'chunk-sportmodDayResultDatepicker' */
      '../../components/sportmod/day-result-datepicker/datepicker'
    );

    sportmodDayResultDatepicker(datepickerCalendarDropdown);
  }
};

export const matchesUpdates = async () => {
  const matchesWrappers = Array.from(
    document.querySelectorAll('[data-matches-auto-update]')
  ) as HTMLDivElement[];

  if (matchesWrappers.length === 0) {
    return;
  }

  const { initAllMatchesUpdates } = await import(
    /* webpackChunkName: 'chunk-sportmod-live-match-websocket-updater' */
    '../../components/sportmod/live-match-websocket-updater/index'
  );

  for (const wrapper of matchesWrappers) {
    initAllMatchesUpdates(wrapper);
  }
};

export const matchDetailPageUpdates = async () => {
  const matchesWrapper = document.querySelector(
    '[data-match-detail-page-auto-update]'
  ) as HTMLDivElement;

  if (!matchesWrapper) {
    return;
  }

  const { initMatchDetailPageUpdates } = await import(
    /* webpackChunkName: 'chunk-sportmod-live-match-detail-page-websocket-updater' */
    '../../components/sportmod/live-match-websocket-updater/index'
  );

  initMatchDetailPageUpdates(matchesWrapper);
};

export const leaguePhaseReloader = async () => {
  const matchesWrapper = document.querySelector('[data-matches-phase-reloader]') as HTMLDivElement;

  if (!matchesWrapper) {
    return;
  }

  const { initLeaguePhaseReloader } = await import(
    /* webpackChunkName: 'chunk-sportmod-league-phase-reloader' */
    '../../components/sportmod/league-phase-reloader/index'
  );

  initLeaguePhaseReloader(matchesWrapper);
};

export const matchesWithTableReloader = async () => {
  const matchesWrapper = document.querySelector(
    '[data-matches-with-table-reloader]'
  ) as HTMLDivElement;

  if (!matchesWrapper) {
    return;
  }

  const { initMatchesWithTableReloader } = await import(
    /* webpackChunkName: 'chunk-sportmod-matches-with-table-reloader' */
    '../../components/sportmod/matches-with-table-reloader/index'
  );

  initMatchesWithTableReloader(matchesWrapper);
};
