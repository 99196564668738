import ChoicesSelect, { Options } from 'choices.js';

// Výchozí možnosti pro všechny select boxy
const defaultOptions: Partial<Options> = {
  searchEnabled: false,
  loadingText: 'Načítám...',
  noResultsText: 'Nebyly nalezeny žádné výsledky.',
  noChoicesText: '',
  itemSelectText: '',
};

// Vytvoření funkce pro inicializaci select boxu s danými možnostmi
export const createCustomSelect = (
  selectElement: HTMLSelectElement,
  customOptions: Partial<Options> = {}
): ChoicesSelect | null => {
  // Sloučení výchozích možností s vlastními možnostmi
  const options: Partial<Options> = {
    ...defaultOptions,
    ...customOptions,
  };

  // Ověření, zda prvek selectElement existuje
  if (!selectElement) return null;

  // Vytvoření nové instance Choices
  return new ChoicesSelect(selectElement, options);
};

export default createCustomSelect;
