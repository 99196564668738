// Pomocné funkce pro práci s SVG soubory
import fetch from 'node-fetch';

// Přidat prvky přístupnosti
interface MakeAccessibleSVGProps {
  svgElement: SVGSVGElement;
  title?: string | undefined;
  description?: string | undefined;
}

const makeAccessibleSVG = ({ svgElement, title, description }: MakeAccessibleSVGProps): void => {
  const labelledby: string[] = [];

  if (title) {
    labelledby.push('svgTitle');
    const titleElement = document.createElementNS('http://www.w3.org/2000/svg', 'title');
    titleElement.textContent = title;
    titleElement.setAttribute('id', 'svgTitle');
    svgElement.appendChild(titleElement);
  }
  if (description) {
    labelledby.push('svgDescription');
    const descElement = document.createElementNS('http://www.w3.org/2000/svg', 'desc');
    descElement.textContent = description;
    descElement.setAttribute('id', 'svgDescription');
    svgElement.appendChild(descElement);
  }

  if (labelledby.length) {
    svgElement.setAttribute('aria-labelledby', labelledby.join(' '));
    svgElement.setAttribute('role', 'img');
  }
};

// Načtení SVG ikony z assetů
export interface LoadSvgFileProps {
  code: string;
  magazine?: string;
  title?: string;
  description?: string;
}

export const loadSvgFile = {
  load: async ({
    code,
    magazine = 'default',
    title,
    description,
  }: LoadSvgFileProps): Promise<SVGSVGElement> => {
    const svgFilePath = loadSvgFile.returnSvgFilePath({ code, magazine });
    const response = await fetch(svgFilePath);
    if (!response.ok) {
      throw new Error(
        `Nepodařilo se načíst SVG soubor "${code}": ${response.status} ${response.statusText}`
      );
    }
    const svgContent = await response.text();

    const parser = new DOMParser();
    const doc = parser.parseFromString(svgContent, 'image/svg+xml');
    const svgElement = doc.documentElement as unknown as SVGSVGElement;

    if (title || description) makeAccessibleSVG({ svgElement, title, description });
    return svgElement;
  },

  returnSvgFilePath: ({ code, magazine = 'default' }: { code: string; magazine?: string }) => {
    return `/dist-isport/assets/${magazine}/${code}.svg`;
  },
};
