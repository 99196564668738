/*
  COMMON GUI(UI) FEATURE MODULES | základní univerzální GUI prvky pro interakci s uživatelem
*/

// PROGRESS BAR | Animace baru při načítání stránky
export const progressBar = async () => {
  const progressBarElement: HTMLElement | null = document.querySelector('[data-progress-bar]');

  if (progressBarElement) {
    const { ProgressBar } = await import(
      /* webpackChunkName: 'default/chunk-progressBar' */
      '../../features/progressBar'
    );

    ProgressBar(progressBarElement);
  }
};

// ACCORDION | Seznam se skrývajícími se tématy
export const accordion = async () => {
  const accordionElements: NodeListOf<HTMLDivElement> =
    document.querySelectorAll('[data-accordion]');

  if (accordionElements && accordionElements.length) {
    const { Accordion } = await import(
      /* webpackChunkName: 'default/chunk-accordion' */
      '@features/accordion'
    );

    Accordion(accordionElements);
  }
};

// FADE IN ON ENTER VIEWPORT | Fade scroll efekt pro jednotlivé elementy
export const fadeInOnEnterViewport = async () => {
  const fadeInElements: NodeListOf<HTMLDivElement> = document.querySelectorAll('[data-fade-in]');

  if (fadeInElements && fadeInElements.length) {
    const { FadeInOnEnterViewport } = await import(
      /* webpackChunkName: 'default/chunk-fadeInOnEnterViewport' */
      '../../features/fadeInOnEnterViewport'
    );

    new FadeInOnEnterViewport(fadeInElements);
  }
};

// MEGAMENU | otevírání a zavírání
export const megamenu = async () => {
  const megamenuElement = document.querySelector('[data-megamenu-nav]');

  if (megamenuElement) {
    const Megamenu = await import(
      /* webpackChunkName: 'default/chunk-megamenu' */
      '@box/megamenu/Megamenu'
    );

    Megamenu.default();
  }
};

export const header = async () => {
  const headerSelector: HTMLElement | null = document.querySelector('header');

  if (headerSelector) {
    const { Header } = await import(
      /* webpackChunkName: 'default/chunk-header' */
      '@box/header/Header'
    );

    Header(headerSelector);
  }
};

export const mainNavigation = async () => {
  const mainNavigationSelector = document.querySelector('[data-main-nav]');

  if (mainNavigationSelector) {
    const { MainNavigation } = await import(
      /* webpackChunkName: 'default/chunk-mainNavigation' */
      '@box/header/MainNavigation/MainNavigation'
    );

    if (mainNavigationSelector instanceof HTMLElement) {
      MainNavigation(mainNavigationSelector);
    }
  }
};

// MODAL | Zobrazení univerzálního modálního okna
export const modal = async () => {
  const modals: NodeListOf<HTMLElement> = document.querySelectorAll('[data-modal]');

  if (modals && modals.length) {
    const { Modal } = await import(
      /* webpackChunkName: 'default/chunk-modal' */
      '@features/modal'
    );

    Modal(modals);
  }
};
