class Infobox {
  // CSS Class Constants
  private static readonly CLASSES = {
    BASE: 'info-box',
    TRANSITION: 'info-box--transition',
    VISIBLE: 'info-box--visible',
    FADE_OUT: 'info-box--fade-out',
    AUTO_HIDE: 'info-box--autohide',
  };

  // Selector Constants
  private static readonly SELECTORS = {
    INFOBOX: '[data-infobox]',
    CLOSE_ELEMENT: '[data-infobox-close]',
  };

  // Auto-hide timeout in milliseconds
  private static readonly AUTO_HIDE_TIMEOUT = 10000;

  private infoboxes: NodeListOf<HTMLElement>;

  constructor() {
    // Select all elements with data-infobox attribute
    this.infoboxes = document.querySelectorAll(Infobox.SELECTORS.INFOBOX);
    this.initializeInfoboxes();
  }

  private initializeInfoboxes() {
    this.infoboxes.forEach((infobox) => {
      // Ensure transition is set up
      infobox.classList.add(Infobox.CLASSES.TRANSITION);

      // Add event listeners for close functionality
      const closeElements = infobox.querySelectorAll(Infobox.SELECTORS.CLOSE_ELEMENT);
      closeElements.forEach((closeEl) => {
        closeEl.addEventListener('click', () => this.closeInfobox(infobox));
      });

      // Handle auto-hide functionality
      if (infobox.classList.contains(Infobox.CLASSES.AUTO_HIDE)) {
        this.setupAutoHide(infobox);
      }

      // Initial visibility state is handled by CSS
      // info-box--visible class will override default hidden state
    });
  }

  private closeInfobox(infobox: HTMLElement) {
    // Add fade-out class
    infobox.classList.add(Infobox.CLASSES.FADE_OUT);

    // Remove visibility and fade-out classes after animation completes
    infobox.addEventListener(
      'transitionend',
      () => {
        infobox.classList.remove(Infobox.CLASSES.VISIBLE, Infobox.CLASSES.FADE_OUT);
      },
      { once: true }
    );
  }

  private setupAutoHide(infobox: HTMLElement) {
    // Auto-hide after few seconds
    setTimeout(() => {
      this.closeInfobox(infobox);
    }, Infobox.AUTO_HIDE_TIMEOUT);
  }

  // Method to manually show an infobox
  public showInfobox(infobox: HTMLElement) {
    infobox.classList.remove(Infobox.CLASSES.FADE_OUT);
    infobox.classList.add(Infobox.CLASSES.VISIBLE);
  }

  // Method to toggle infobox visibility
  public toggleInfobox(infobox: HTMLElement) {
    if (infobox.classList.contains(Infobox.CLASSES.VISIBLE)) {
      this.closeInfobox(infobox);
    } else {
      this.showInfobox(infobox);
    }
  }
}

// Initialize the Infobox when the DOM is fully loaded
document.addEventListener('DOMContentLoaded', () => {
  new Infobox();
});

export default Infobox;
